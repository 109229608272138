const toString = Object.prototype.toString;
export function is(val: unknown, type: string) {
  return toString.call(val) === `[object ${type}]`;
}

export function isDef<T = unknown>(val?: T): val is T {
  return typeof val !== "undefined";
}

export function isUnDef<T = unknown>(val?: T): val is T {
  return !isDef(val);
}

export function isObject(val: any): val is Record<any, any> {
  return val !== null && is(val, "Object");
}

export function isEmptyObject(val: any): val is Record<any, any> {
  return isObject(val) && JSON.stringify(val) === '{}';
}

export function isEmpty<T = unknown>(val: T): val is T {
  if (isArray(val) || isString(val)) {
    return val.length === 0;
  }

  if (val instanceof Map || val instanceof Set) {
    return val.size === 0;
  }

  if (isObject(val)) {
    return Object.keys(val).length === 0;
  }

  return false;
}

export function isDate(val: unknown): val is Date {
  return is(val, "Date");
}

export function isNull(val: unknown): val is null {
  return val === null;
}

export function isNullAndUnDef(val: unknown): val is null | undefined {
  return isUnDef(val) && isNull(val);
}

export function isNullOrUnDef(val: unknown): val is null | undefined {
  return isUnDef(val) || isNull(val);
}

export function isNumber(val: unknown): val is number {
  return is(val, "Number");
}

export function isPromise<T = any>(val: any): val is Promise<T> {
  // 不能既是 Promise 又是 Object --------
  return is(val, "Promise") && isFunction(val.then) && isFunction(val.catch);

  //  不能既是 Promise 又是 Object --------
}

export function isString(val: unknown): val is string {
  return is(val, "String");
}

export function isJsonObjectString(val: string): val is string {
  if (!isString(val) || !val || typeof val == "number") {
    return false;
  }
  return val.startsWith("{") && val.endsWith("}");
}

export function isJsonArrayString(val: string): val is string {
  if (!isString(val) || !val || typeof val == "number") {
    return false;
  }
  try {
    eval(val);
    return val.startsWith("[") && val.endsWith("]");
  } catch {
    return false;
  }
}

export function isParamsString(val: string): val is string {
  if (!isString(val) || !val || typeof val == "number") {
    return false;
  }
  return val.startsWith("#{") && val.endsWith("}");
}

export function isCustomJsString(val: string): val is string{
  if (!isString(val) || !val || typeof val == "number") {
    return false;
  }
  return val.includes('return');
}

export function isFunction(val: unknown): val is Function {
  return typeof val === "function";
}

export function isBoolean(val: unknown): val is boolean {
  return is(val, "Boolean");
}

export function isRegExp(val: unknown): val is RegExp {
  return is(val, "RegExp");
}

export function isArray(val: any): val is Array<any> {
  return val && Array.isArray(val);
}

export function isWindow(val: any): val is Window {
  return typeof window !== "undefined" && is(val, "Window");
}

export function isElement(val: unknown): val is Element {
  return isObject(val) && !!val.tagName;
}

export function isMap(val: unknown): val is Map<any, any> {
  return is(val, "Map");
}

export const isServer = typeof window === "undefined";

export const isClient = !isServer;

/*eslint-disable no-useless-escape*/
export function isUrl(path: string): boolean {
  const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return reg.test(path);
}

export function isJSON(str:string, passObject?:any) {
  if (passObject && isObject(str)) return true;

  if (isString(str)) return false;
  str = str as string;
  str = str.replace(/\s/g, '').replace(/\n|\r/, '');

  if (/^\{(.*?)\}$/.test(str)) return /"(.*?)":(.*?)/g.test(str);

  if (/^\[(.*?)\]$/.test(str)) {
    return str
      .replace(/^\[/, '')
      .replace(/\]$/, '')
      .replace(/},{/g, '}\n{')
      .split(/\n/)
      .map(s => {
        return isJSON(s);
      })
      .reduce((prev, curr) => {
        return !!curr;
      });
  }
  return false;
}
