import { removeElement, addElement } from "../../utils/utils";
import { IElement, PageElement } from "./element";
import { IEvent } from "./event";
import { IDataSourcesType } from "./datasource";
import { LoadTypeEnum, PageLayoutEnum } from "../../enums";
import { deepClone, mergeDeep } from "../../utils/utils";
import { IBackground, IImage } from './projectSetting'
export interface IPage {

  /** Id */
  id: string;

  /** 名称 */
  name: string;

  /** 描述 */
  description: string;

  /** 元素 */
  elements: IElement[];

  // 事件
  events: Record<string, IEvent[]>;

  // 属性
  attribute: attrType;

  // 数据源
  dataSources: Record<string, IDataSourcesType>;

  // 路由元数据
  routerInfo: IRouterInfo;

  pageStatus: boolean;

  //管理所有组件的序号
  indexManager: Record<string, number>;

  //文件夹关系
  folderRelation?: string;

  //是否已被收藏
  isFavority: boolean;

  // 父页面id
  pPageId: string;
}

export let attributeTemplate = {
  name: "New Page",
  icon: {
    name: '',
    path: '',
  },
  layout: "flow",
  canvasSize: {
    width: 1920,
    height: 1080,
  },

  isScroll: true,
  pageDepthWidth: 1200,
  isScale: true,
  background: {
    type: 'color',
    value: "rgba(255,255,255,1)",
  },
  loadColor: "rgba(255,255,255,.5)",
  loadText: "加载中...",
  isGrid: true,
  loadType: LoadTypeEnum.NONE,
  isNameCustom: false,
  isIconCustom: false,
  isBackgroundCustom: false,
  bgRepeat: "no-repeat",
  bgSize: '100% 100%'
}

//设置属性的分辨率
function getNewAttr(): attrType {
  if ((window as any)['PAGEEDITOR']?.GlobalInstance) {
    let GlobalInstance = (window as any)['PAGEEDITOR'].GlobalInstance;
    let resolutionPower = GlobalInstance.getGlobalParams('resolutionPower');
    if (resolutionPower) {
      const defaultDpi = JSON.parse(resolutionPower)
      attributeTemplate.canvasSize.width = defaultDpi.width;
      attributeTemplate.canvasSize.height = defaultDpi.height;
    }
  }

  let attr = deepClone(attributeTemplate);
  return attr
}

export class Page implements IPage {
  "": any;
  public static create(p?: IPage) {
    const page = new Page();
    if (p) {
      page.name = p.name || page.name;
      page.id = p.id || page.id;
      page.description = p.description || page.description;
      page.elements = p.elements?.map((element) => PageElement.create(element)) || [];
      page.events = p.events || page.events;
      page.attribute = p.attribute || page.attribute;
      page.dataSources = p.dataSources || page.dataSources;
      page.routerInfo = p.routerInfo || page.routerInfo;

      // page.routerInfoNumber = p.routerInfoNumber;
      page.pageStatus = p.pageStatus || page.pageStatus;
      page.indexManager = p.indexManager || page.indexManager;
      page.folderRelation = p.folderRelation || page.folderRelation;
      page.isFavority = p.isFavority || page.isFavority;
      page.pPageId = p.pPageId || page.pPageId;
    }
    return page;
  }
  public pageStatus = false;

  // public id = randomPageId().toString();
  public id = "@pageId@";

  public name = "";

  public description = "New Page Description";

  // private elements: PageElement[] = [];

  public events = {};

  public dataSources = {};

  public attribute = getNewAttr();

  public indexManager = {};

  public folderRelation = "";

  // 创建默认路由元数据
  public routerInfo: IRouterInfo = {
    pageId: "",
  };

  public recordStack: Record<string, any>[] = [];

  public stackIndex: number = 0;

  public elements: PageElement[] = [];

  public isFavority: boolean = false;

  public pPageId: string = "";

  // public get elements() {
  //   return this.elements.map((p) => p.getRecord());
  // }

  // 支持在页面数据中添加或是在某容器数据中添加
  public addElement(element: PageElement, parentElementId?: string, currentId: streing) {
   addElement(this.elements, element,currentId, parentElementId);
  }

  public getElementById(id: string, component?) {
    let result = null;
    let components = component || this.elements;
    for (let i = 0, len = components.length; i < len; i++) {
      let component = components[i];
      if (component.id == id) {
        result = component;
        return result;
      } else {
        if (component.children && component.children.length > 0) {
          result = this.getElementById(id, component.children);
          if (result) {
            return result;
          }
        }
      }
    }
  }

  // 根据index改变list
  public alterList(onList, targetElementId?) {
    if (targetElementId) {
      let list = this.getElementById(targetElementId);
      onList(list.children);
      return;
    }
    onList(this.elements);
  }

  public getElementByMid(mid: number) {
    return this.elements.filter((item) => item.mId === mid);
  }

  // 支持在页面数据中移除或是在某容器数据中移除
  public removeElement(id: string) {
    this.elements = removeElement(this.elements, id);
  }

  // 支持页面中的elements全部直接更新
  public setCurrentPageElements(elements) {
    this.elements = elements.map((element) => PageElement.create(element));
  }

  // 清空页面组件
  public clearCurrentPageElements() {
    this.elements = [];
    this.indexManager = {};
    this.events = {};
    this.dataSources = {};
    this.routerInfo = Object.assign(this.routerInfo, {
      children: [],
      extRouteInfo: [],
    })
  }

  /**
   * 直接替换某个组件的event
   * @author zhangsy 2023年12月05日 16:45:28
   * @id 组件id
   * @events 该组件的事件
   * @return param
   * */
  public setEvents(id, events) {
    this.events[id] = events;
  }

  //保存页面组件事件
  public addEvent(id: string, iEvent: any, index: number) {
    if (this.events[id]) {
      this.events[id][index] = iEvent;
    } else {
      (this.events[id] = []).push(iEvent);
    }
  }

  //删除事件
  public delEvent(id: string, index?: number) {
    if (index) {
      if (this.events[id]) {
        this.events[id].splice(index, 1);
      }
    } else {
      delete this.events[id];
    }
  }

  public getEvent(id: string) {
    return this.events[id] || [];
  }

  public setAttr(attr) {
    this.attribute = {
      ...this.attribute,
      ...attr,
    };
  }

  public setPageParams(key, value) {
    // if (key === "routerInfo") {
    //   this[key].pageId = value;
    // } else {
    this[key] = value;

    // }
  }

  public mergePageParams(obj) {
    mergeDeep(this, obj);
    console.log("this", this);
  }

  /**
   * 添加数据源
   * @author zhangsy 2023年10月21日 15:36:31
   * @param {v} 数据源信息
   * @param {id} 组件id
   * @return param
   * */
  public addDataSources(v: any, id: string) {
    this.dataSources[id] = v;
  }

  /**
   * 删除数据源
   * @author zhangsy 2023年10月21日 15:36:31
   * @param {id} 组件id
   * @return param
   * */
  public delDataSources(id: string) {
    if (this.dataSources[id]) {
      delete this.dataSources[id];
    }
  }

  public getDataSources(id: string) {
    return this.dataSources[id] || [];
  }

  // 返回组件序号组
  public getIndexManager() {
    return this.indexManager;
  }

  // 修改组件序号
  public setIndexManager(key, num) {
    if (!this.indexManager) {
      this.indexManager = {};
    }
    this.indexManager[key] = num;
  }

  //修改页面的父级关系
  public setFolderRelation(folderRelation: string) {
    this.folderRelation = folderRelation
  }

  public setParentPageId(id: string) {
    this.pPageId = id;
  }

  // 返回组件序号组
  public getParentPageId() {
    return this.pPageId;
  }

  public getRecord(): IPage {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      elements: this.elements,
      events: this.events,
      attribute: this.attribute,
      dataSources: this.dataSources,
      routerInfo: this.routerInfo,

      // routerInfoNumber: this.routerInfoNumber,
      pageStatus: this.pageStatus,
      indexManager: this.indexManager,
      folderRelation: this.folderRelation,
      isFavority: this.isFavority,
      pPageId: this.pPageId,
    };
  }
}

export interface IRouterInfo {
  pageId: string;
  children?: IChildRouterInfo[];
  extRouteInfo?: IChildRouterInfo[];
}
export interface IRouterPath {
  pageId: string;
  path: string;
}
export interface IChildRouterInfo {
  pageId: string;
  compName: string;
  type: string;
  inMenu?: string | '';
  oldPage?: any;
}
export interface IPageInfo {
  pageData: IPage | {};
  routerInfo?: IRouterInfo | {};
  compParams: {};
  compDependency: Array<string>;
}

export interface PageTreeEle {
  id: number;
  name: string;
}
export type PageTree = PageTreeEle[];

// 生成页面模板接口
export interface IPageTemplate {

  // 模板
  templateCode?: string;

  // 引入依赖项
  scriptCode?: string;

  // 引入组件
  componentsCode?: string;

  // 样式
  styleCode?: string;

  // 完整模板
  completeCode?: string;
}

export interface SkeletonStyle {
  width: string;
  height: string;
  top: string;
  left: string;
}

// 页面属性
export interface attrType {
  name: string;
  icon: IImage,
  layout: PageLayoutEnum;
  canvasSize: {
    width: number;
    height: number;
  };

  // isKeepAlive: boolean;
  isScroll: boolean;
  isScale: boolean;
  pageDepthWidth: number,
  background: IBackground;
  loadType: LoadTypeEnum,
  loadColor: string;
  loadText: string;
  isGrid: boolean;
  isNameCustom: boolean,
  isIconCustom: boolean,
  isBackgroundCustom: boolean,
}

export interface DomPosition {
  [key: string]: number;
}

export interface AfterDomPosition {
  afterLeft: string | number;
  afterTop: string | number;
}

export interface CanvasSize {
  width: number;
  height: number;
}
